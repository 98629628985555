@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: "CircularStdBlack";
      src: url(/src/assets/Circular Std Black.ttf) format("truetype");
    }
    @font-face {
        font-family: "CircularStdBold";
        src: url(/src/assets/Circular Std Bold.ttf) format("truetype");
      }
    @font-face {
        font-family: "CircularStdBook";
        src: url(/src/assets/Circular Std Book.ttf) format("truetype");
      }
    @font-face {
        font-family: "CircularStdMedium";
        src: url(/src/assets/Circular Std Medium.ttf) format("truetype");
      }
  }

  @layer utilities {
    .border-1 {
      border-width: 1px;
    }
    
    .text-upper {
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .timeline-inactive {
      border-color: #2D353E;
    }

    .timeline-active-1 {
      border-color: #2D353E;
      border-image: linear-gradient(to bottom, white 0%, white 50%, #2D353E 50%, #2D353E 100%);
      border-image-slice: 1;
    }

    .timeline-active-2 {
      border-color: white;
    }

    .full-circle {
      left: -0.95rem;
      bottom: 1.7rem;
      height: 1.5rem;
      width: 1.5rem;
      -moz-border-radius:50%;
      -webkit-border-radius:50%;
      position:absolute;
     }

     .top-18 {
       top: 4.5rem;
     }
  }

 